// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "H_gV";
export var devopsBenefitSection = "H_gJ";
export var devopsBusinessSection = "H_gG";
export var devopsDomainSection = "H_gQ";
export var devopsExpertiseSection = "H_gP";
export var devopsProjLogoSection = "H_gT";
export var devopsProvideSection = "H_gK";
export var devopsResultsSection = "H_gL";
export var devopsSuccessStoriesSection = "H_gR";
export var devopsTestimonialPostSection = "H_gM";
export var devopsToolkitSection = "H_gN";
export var devopsVideoReviewSection = "H_gW";
export var devopsWhyChooseSection = "H_gS";
export var devopsWhyNeedSection = "H_gH";