// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dp_Bs";
export var servicesToolkitSection__card = "dp_Bt";
export var servicesToolkitSection__icons = "dp_Bv";
export var servicesToolkitSection__serviceToolkitIcon1 = "dp_Bx";
export var servicesToolkitSection__serviceToolkitIcon10 = "dp_BJ";
export var servicesToolkitSection__serviceToolkitIcon11 = "dp_BK";
export var servicesToolkitSection__serviceToolkitIcon12 = "dp_BL";
export var servicesToolkitSection__serviceToolkitIcon13 = "dp_BM";
export var servicesToolkitSection__serviceToolkitIcon14 = "dp_BN";
export var servicesToolkitSection__serviceToolkitIcon15 = "dp_BP";
export var servicesToolkitSection__serviceToolkitIcon16 = "dp_BQ";
export var servicesToolkitSection__serviceToolkitIcon17 = "dp_BR";
export var servicesToolkitSection__serviceToolkitIcon18 = "dp_BS";
export var servicesToolkitSection__serviceToolkitIcon19 = "dp_BT";
export var servicesToolkitSection__serviceToolkitIcon2 = "dp_By";
export var servicesToolkitSection__serviceToolkitIcon20 = "dp_BV";
export var servicesToolkitSection__serviceToolkitIcon3 = "dp_Bz";
export var servicesToolkitSection__serviceToolkitIcon4 = "dp_BB";
export var servicesToolkitSection__serviceToolkitIcon5 = "dp_BC";
export var servicesToolkitSection__serviceToolkitIcon6 = "dp_BD";
export var servicesToolkitSection__serviceToolkitIcon7 = "dp_BF";
export var servicesToolkitSection__serviceToolkitIcon8 = "dp_BG";
export var servicesToolkitSection__serviceToolkitIcon9 = "dp_BH";
export var servicesToolkitSection__sprite = "dp_Bw";
export var servicesToolkitSection__title = "dp_BW";